.group {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: start;
}

.group.rtl {
    justify-content: end;
}

.button {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dropdown-button {
    flex-grow: 0 !important;
}

.group.has-collapsed-items .button {
    flex-grow: 1;
}

.group.has-one-visible-item .button {
    overflow: hidden;
}
