.dropdown {
    position: absolute;
    border: 1px solid var(--main-border-color);
    border-radius: var(--border-radius);
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: block;
    width: max-content;
    max-width: calc(100vw - 2rem);
    z-index: 50;
}

.menu {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
    overflow-x: hidden;
}

.menu-item {
    all: unset;
    font-size: 0.875rem;
    color: #374151;
    font-weight: 400;
    display: block;
    padding: 0.5rem 1rem;
    word-wrap: break-word;
    text-align: left;
    width: 100%;
}

.menu-item:hover {
    background-color: #f3f4f6;
    color: #374151;
}

.menu-item:disabled {
    color: #9ca3af;
    pointer-events: none;
}

.menu-item.loading {
    pointer-events: none;
    opacity: 0.6;
}

.menu-item.opacity-loading {
    pointer-events: none;
    opacity: 0.6;
}

.content-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
    justify-content: space-between;
    align-items: center;
}

.content-loader-wrapper {
    display: inline-flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
}

.menu-item.loading .content-loader-wrapper i[class*='fa'] {
    display: none;
}

.loading-animation {
    display: none;
    height: 0.85rem;
}

.menu-item.loading .content-loader-wrapper img.loading-animation {
    display: block;
}

.item-check {
    color: var(--main-color);
}
